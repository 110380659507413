<template>
  <b-container>
    <b-row class="mb-2">
      <b-col cols="12">
        <h2>
          Bienvenue dans votre interface Agriprogress
        </h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <b-card class="h-100">
          <b-row
            align-h="center"
            class="h-100"
          >
            <b-col cols="12">
              <h4>Notre démarche HVE</h4>
            </b-col>
            <b-col
              cols="12"
              class="my-2 text-center"
            >
              <b-img
                src="@/assets/images/illustrations/hve.png"
                alt="logo hve"
              />
            </b-col>
            <b-col
              cols="12"
              align-self="end"
            >
              <b-button
                to="hve"
                class="w-100"
                type="button"
                variant="primary"
                pill
              >
                En savoir plus
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="6">
        <b-card class="h-100">
          <b-row
            align-h="center"
            class="h-100"
          >
            <b-col cols="12">
              <h4>Les pratiques de vos exploitants</h4>
            </b-col>
            <b-col
              cols="12"
              class="my-2 text-center"
            >
              <b-img
                src="@/assets/images/illustrations/exploitant.svg"
                alt="logo hve"
              />
            </b-col>
            <b-col
              cols="12"
              align-self="end"
            >
              <b-button
                to="practices"
                class="w-100"
                type="button"
                variant="primary"
                pill
              >
                En savoir plus
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BContainer, BRow, BCol, BCard, BImg, BButton
} from "bootstrap-vue";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
    BImg,
    BButton
  }
};
</script>
